import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { BsPeopleFill } from 'react-icons/bs'
import { FaHome, FaHeart, FaCamera, FaTemperatureHigh, FaPaw, FaWind } from 'react-icons/fa';
import { FaWarehouse, FaPaintBrush, FaShoppingCart, FaShoppingBag, FaAnchor, FaTrain, FaFish, FaGolfBall, FaUtensils, FaCar, FaTree } from 'react-icons/fa';

import * as indexStyles from "../../styles/components/index.module.sass"
import cx from 'classnames'

const FeaturesAndThingsToDo = () => {

    return (
        <Container>
            <Row style={{ overflow: "hidden" }} className="justify-content-around">
                <Col md="4" style={{}} className="blueBox my-2">
                    <Container className={cx("text-left", indexStyles.item)}>
                        <Row>
                            <Col md="12">
                                <h3 className={indexStyles.infoHeader}>Features</h3>
                                <p><BsPeopleFill /> No close neighbours</p>
                                <p><FaHome /> Cosy and secure accommodation</p>
                                <p><FaTemperatureHigh /> Central heating</p>
                                <p><FaHeart /> Romantic rural atmosphere</p>
                                <p><FaCamera /> Spectacular views from on high</p>
                                <p><FaPaw /> Birds, foxes, badgers and other wildlife</p>
                                <p><FaWind /> Peace and Tranquility</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col md="7" style={{}} className="blueBox my-2" >

                    <Container className={cx("text-left", indexStyles.item)}>
                        <Row>
                            <Col md="12">
                                <h3 className={indexStyles.infoHeader}>Things to do</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" sm="12">
                                <p><FaWarehouse /> Visit caves and museums</p>
                                <p><FaPaintBrush /> Beautiful arts and crafts at Dartington</p>
                                <p><FaShoppingBag /> Country markets at Totnes</p>
                                <p><FaShoppingCart /> Antique shops</p>
                                <p><FaAnchor /> Old pumping engine and sailing at Dartmouth</p>
                                <p><FaTrain /> Steam railways</p>
                            </Col>
                            <Col md="6" sm="12">
                                <p><FaFish /> Fishing port at Brixham</p>
                                <p><FaGolfBall /> Golf and leisure centres in Torbay</p>
                                <p><FaUtensils /> Wide choice of hospitable pubs and restaurants</p>
                                <p><FaCar /> Take a day trip to the Eden project</p>
                                <p><FaTree /> National Trust gardens and castles</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default FeaturesAndThingsToDo
