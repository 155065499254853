import { FaPhone } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md' 
import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"

const ContactSummary = () => {

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md="8" sm="12" className="yellowBox">
                    <p><Link to="/contact">Contact us</Link> for more information or to book</p>
                    <FaPhone className="align-middle" /> 
                    <p><a href="tel:00447941313141">07941 313141</a> or <a href="tel:00441803472930’">01803 472930</a></p>
                    <MdEmail className="align-middle" />
                    <p><a href="mailto:enquiries@devonwindmills.co.uk"> enquiries@devonwindmills.co.uk</a></p> 
                </Col>
            </Row>
        </Container>
    );
}

export default ContactSummary