import React from "react"
import { Jumbotron } from "react-bootstrap"
import { Row, Col, Container } from "react-bootstrap"

import * as indexStyles from "../../styles/components/index.module.sass"
import cx from 'classnames'

const JumboSummary = () => {

    return (
        <Container>
            <Row>
                <Col>
                    <Jumbotron className={cx(indexStyles.jumbotron, indexStyles.item)}>
                        Stay for a long or short break in our beautiful restored windmill tower, a Devon holiday cottage with a difference.
                        The mill is set in the picturesque Devon countryside, four miles inland from the beaches of Torbay, between the South Hams, Dartmoor and the sea
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}

export default JumboSummary
