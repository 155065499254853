import React from "react"

import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactSummary from "../components/indexPage/contactSummary"
import FeaturesAndThingsToDo from "../components/indexPage/featuresAndThingsToDo"
import YoutubeVideo from "../components/indexPage/youtubeVideo"
import JumboSummary from "../components/indexPage/jumboSummary"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} showBanner={true}>
    <SEO title="Home" keywords={[`devon`, `windmill`, `holidays`, `cottage`]} />
    <Container className="text-center">
      <JumboSummary />
      <ContactSummary />
      {/* <YoutubeVideo /> */}
      <FeaturesAndThingsToDo />
    </Container>
  </Layout >
)

//      (between ContactSummary and FeaturesAndThingsToDo)


export default IndexPage
